<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
          <div class="col-xl-12">
            <!--begin: Wizard Form-->
            <Form
              :form="form"
              :route="'village-business/' + $route.params.id"
              purpose="edit"
              title="Edit Data Tempat Usaha"
            />
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/village-business/Form.vue";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    async get() {
      this.form = await module.get("village-business/" + this.$route.params.id);
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/village-business/list");
      } else {
        this.form["_method"] = "put";
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Data Tempat Usaha", route: "/village-business/list" },
      { title: "Edit Tempat Usaha" },
    ]);

    this.get();
  },
};
</script>
